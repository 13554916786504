.container {
  margin-left: 20px;
  max-height: 500px;
}
.container label {
  cursor: pointer;
}
div[ref='eFloatingFilterText'] input {
  border: none !important;
  font-weight: 600;
}
div[ref='eFloatingFilterText'] div[ref='eWrapper'] {
  border: none !important;
}
.container label,
.container p {
  font-size: 18px;
  height: 25px;
  border-radius: 3px;
}
