.required::before {
  content: '*';
  color: red;
}

.required {
  font-weight: bold;
}

.label {
  font-size: 14px;
}

.dropdownWrapper {
  width: 100%;
}

div[class*='-MenuList'] {
  background-color: #ffffff;
}

div[class*='-menu'] > div {
  background-color: #ffffff;
}

div[class*='-menu-outer'] {
  background-color: #ffffff;
}

.Select-menu-outer {
  z-index: 100 !important;
}

.error {
  color: red;
  font-size: 16px;
  margin-bottom: 5px;
}
