@import '../../assets/styles/variables.scss';

.button {
  min-width: 90%;
  min-height: 40px;
  color: #fff;
  font-size: 18px;
  border: none;
  background-color: #007e93;
  line-height: 22px;
  margin: 2% 10px;
  padding: 0 30px;
  border-radius: 4px;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.container {
  display: flex;
  align-items: flex-start;
  height: 50px;
  justify-content: flex-end;
}
