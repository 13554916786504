.tabs {
  border-bottom: 1px solid #1b4a83;
  padding-left: 0;
  margin-bottom: 0;
}

.tab {
  cursor: pointer;
  display: inline-block;
  color: #1b4a83;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}
.disabledTab {
  color: gray;
}
.selected {
  font-weight: bold;
  background-color: white;
  border: solid #1b4a83;
  color: #1b4a83;

  border-width: 1px 1px 0 1px;
}
