.label {
  font-size: 14px;
}
.container input {
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container label,
.subContainer label {
  margin-left: 5px;
}

.sub {
  margin-left: 30px;
}
