.container {
  margin: 20px 0;
}
.container a {
  margin: 20px 0;
  text-decoration: none;
}
.disabled {
  color: gray;
  font-weight: bold;
  cursor: default;
}
