@use 'sass:math' as math;
@import '../../assets/styles/variables.scss';

$button-sm: 20;
$button-md: 50;
$button-lg: 70;
$button-xl: 100;

$map-button-props: (
  'sm': $button-sm,
  'md': $button-md,
  'lg': $button-lg,
  'xl': $button-xl,
);

.button {
  background-color: $mainColor;
  color: $contentBackgroundColor;
  line-height: 22px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 0 solid;
  transition: background 0.5s ease-in-out;
  cursor: pointer;
  &:disabled {
    background-color: $disabledBackgroundColor;
    transition: none;
    color: $disabledColor;
    border: 0;
    cursor: not-allowed;
    &:hover {
      background-color: $disabledBackgroundColor;
    }
  }
  &:hover {
    background-color: $hoverColor;
  }
  @each $modifier, $size in $map-button-props {
    &__#{$modifier} {
      width: $size * 1%;
      padding: $size * 1px;
    }
  }
  &__alt {
    background-color: $altColor;
    &:hover {
      background-color: $altHoverColor;
    }
  }
}
