.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.required {
  font-weight: bold;
}

.required::before {
  content: '*';
  color: red;
}
