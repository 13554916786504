@import './../../assets/styles/variables.scss';

.container {
  color: #1b4a83;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  margin: 35px 0 20px 0;
}

.description {
  font-size: 14px;
}
