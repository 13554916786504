@import '../../assets/styles/variables.scss';
.container {
  margin: auto;
  margin-top: 10;
  height: 200;
  width: 100%;
}

.headTitle {
  font-size: $headTitleFontSize;
  color: $mainColor;
  font-family: $titleFontFamily;
}

.addNew {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 20px;
}
.icon {
  color: #1b4a83;
  padding-right: 5px;
}
