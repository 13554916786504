@import '../../assets/styles/variables.scss';

.checkbox:checked {
  background-color: $mainColor;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.itemWrapper {
  display: flex;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.itemWrapper input[type='checkbox'] + label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0px 0px 0px 2.5rem;
  text-align: left;
  width: 100%;
}

.itemWrapper input[type='checkbox']:hover + label::before {
  cursor: pointer;
}

.itemWrapper input[type='checkbox']:hover + label::after {
  cursor: pointer;
}

.itemWrapper input[type='checkbox'] + label::before {
  content: '';
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 2px solid $mainColor;
  border-radius: 4px;
  display: block;
  position: absolute;
  left: 0px;
  top: -4px;
}

.itemWrapper input[type='checkbox']:focus + label::before {
  box-shadow: 3px 3px $mainColor, 3px -3px $mainColor, -3px 3px $mainColor,
    -3px -3px $mainColor;
}

.itemWrapper input[type='checkbox']:checked + label::before {
  background: $mainColor;
  border: 2px solid $mainColor;
}

.itemWrapper input[type='checkbox']:checked:focus + label::before {
  background: $mainColor;
}

.itemWrapper input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 7px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  height: 6px;
  width: 12px;
  transform: rotate(-45deg);
}

/* Hide the native checbox in an accessible manner */
.itemWrapper input[type='checkbox'] {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

/* Disabled checkbox styles */
.itemWrapper input[type='checkbox']:disabled + label::before {
  background-color: #fcfcfc;
  border-color: #bcbcbc;
}

.errors {
  color: red;
  margin-top: -10px;
  font-size: 16px;
  margin-bottom: 5px;
}

.disabled input[type='checkbox']:checked + label::after,
.disabled input[type='checkbox']:hover + label::before,
.disabled input[type='checkbox'] + label,
.disabled {
  color: #7d7d7d;
  cursor: not-allowed;
}
