@import './../../assets/styles/variables.scss';

.header {
  background-color: $mainColor;
  color: $titleColor;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 0;
}

.title {
  color: $titleColor;
  font-size: $titleFontSize;
  line-height: $titleLineHeight;
  display: flex;
}

.buttontoLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}
.formButton {
  min-width: 240px;
  height: 40px;
  color: #fff;
  font-size: 18px;
  border: none;
  background-color: $mainColor;
  line-height: 22px;
  margin-top: 30px;
  padding: 0 30px;
  border-radius: 4px;
}

.checkBoxContainer {
  display: flex;
  align-items: flex-end;
  margin: 15px 0;
}

.checkBoxContainer label {
  margin-left: 10px;
  font-size: 14px;
}
.checkBoxContainer input {
  transform: scale(2);
  padding: 10px;
}

.confidence {
  display: flex;
  flex-direction: row-reverse;
}
