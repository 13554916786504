.disabled {
  color: gray;
  pointer-events: none;
  cursor: not-allowed;
}

.addNew {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  min-width: 4em;
}

.container svg {
  padding: 0;
  font-size: 23px;
  margin: 0 20px;
  cursor: pointer;
}

.controler {
  animation: fadeoout 0.3s forwards;
}
.controler1 {
  animation: fadeoout1 0.3s forwards;
}

@keyframes fadeoout {
  0% {
    transform: translateY(-100px);
    opacity: 0.4;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeoout1 {
  0% {
    opacity: 0.4;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
