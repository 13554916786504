@import '../../assets//styles/variables.scss';

.title_input {
  font-size: 14px;
  height: 37px;
  outline-color: $outlineColor;
  background-color: $inputBGColor;
  border-radius: $inputRadius;
  border: $inputBorder;
  padding: 1px 10px;
}

.title_input:disabled {
  background-color: $inputDisabledBGColor;
  border: $inputDisabledBorder;
}

.error {
  color: red;
  font-size: 16px;
  margin-bottom: 5px;
}
