@import './../../assets/styles/variables.scss';

.formButton {
  min-width: 240px;
  width: unset;
  height: 40px;
  color: #fff;
  font-size: 18px;
  border: none;
  background-color: $mainColor;
  line-height: 22px;
  margin-top: 30px;
  padding: 0 30px;
  border-radius: 4px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  border-radius: 34px;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  border-radius: 50%;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: red;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: #009c19;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

input:focus + .slider:before {
  box-shadow: 0 0 1px #2196f3;
}

.sliderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.expiredText {
  font-weight: bold;
  margin-left: 18px;
  margin-bottom: 0px;
}
