.container,
.container option {
  font-size: 16px;
  border: none;
  min-height: 40px;
  background-color: inherit;
}
.container:hover,
.container option:hover {
  background-color: inherit;
}

.container option {
  margin: 10px;
}
.wait {
  margin: 0;
}
