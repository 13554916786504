@import '../../assets/styles/variables.scss';

.wrapper {
  padding: 15px 0 200px 0;
}
.userAvatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.userInfoLogout {
  text-align: left;
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.logContainer {
  display: flex;
  margin: 6px 15px 0 0;
}
.upperContent {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  padding-bottom: 5px;
  margin: -2px;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 18%;
  cursor: pointer;
}
