@import '../../assets/styles/variables.scss';
.container {
  margin: auto;
  margin-top: 10;
  height: 200;
  width: 100%;
}

.container button:hover {
  background-color: #e2efff;
}

.container button:active {
  background-color: #e2efff;
  transform: scale(0.98);
}

.headTitle {
  font-size: $headTitleFontSize;
  color: $mainColor;
  font-family: $titleFontFamily;
}
