// Grid system version 0.1
$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$map-grid-props: (
  '': 0,
  '-sm': $grid__bp-sm,
  '-md': $grid__bp-md,
  '-lg': $grid__bp-lg,
  '-xl': $grid__bp-xl,
);

$grid__cols: 12;
$grid__offset_max: 12;
$grid__offset_max_cols: 11;

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint * 1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid__cols, $breakpoint) {
  @include create-mq($breakpoint, 'min') {
    &__col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid__cols {
      &__col#{$modifier}-#{$i} {
        flex-basis: ((100 - $grid__offset_max)/ ($grid__cols / $i)) +
          ($grid__offset_max / $grid__offset_max_cols * ($i - 1)) *
          1%;
      }
      &__col#{$modifier}-offset-#{$i} {
        margin-left: ($grid__offset_max / $grid__offset_max_cols) * 1%;
      }
    }
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @if ($modifier == '') {
    $modifier: '-xs';
  }
  @include create-mq($breakpoint - 1, 'max') {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, 'min') {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.container {
  max-width: $grid__bp-md * 1px;
  margin: 0 auto;
  &--fluid {
    margin: 0;
    max-width: 100%;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}
