@import '../../assets/styles/variables.scss';

.filterContainer {
  margin-top: 20px;
  label {
    font-weight: bold;
  }
}

.submitContainer {
  position: relative;
}

.gridContainer {
  max-width: 1600px;
}

.searchButton {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  font-weight: normal;
}

.headTitle {
  font-size: 1.4em;
  font-weight: bold;
  color: $mainColor;
  font-family: $titleFontFamily;
}

.tabs {
  margin-top: 40px;
}

.helpDescription {
  p {
    margin-top: 30px;
    max-width: 1000px;
  }
}
