@import '../../assets/styles/variables.scss';

.headTitle {
  font-size: $headTitleFontSize;
  color: $mainColor;
  font-family: $titleFontFamily;
}
.editIcom {
  font-size: 22px;
  transform: translateY(110%);
  color: $mainColor;
  margin: 10px;
  cursor: pointer;
}

input:disabled {
  background: #f2f2f2;
}
.ssn,
.ssn:disabled {
  background: #fff;
  border: none;
}
.control {
  @extend .editIcom;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control div {
  margin: 0 3px;
}

.phoneNumberAndNotReachedBlock {
  display: block;
  & > div {
    display: inline-block;
    width: calc(50% - 5px);
    &:first-child {
      padding-right: 10px;
    }
  }
  & > div:last-child > div {
    display: flex;
    flex-direction: column;
  }
}
