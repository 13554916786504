@import './../../assets/styles/variables.scss';

.header {
  background-color: $mainColor;
  color: $titleColor;
  border-radius: 6px;
  height: 100%;
  min-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}
.childrn {
  display: flex;
  flex-direction: row-reverse;
}
.title {
  color: $titleColor;
  padding-left: 10px;
  font-size: $titleFontSize;
  font-weight: $titleFontWeight;
  line-height: $titleLineHeight;
}

.gridSettings {
  display: flex;
  gap: 40px;
  justify-content: left;
  margin: 10px 0;
}

@media only screen and (max-width: 990px) {
  .gridSettings {
    justify-content: center;
  }
}
