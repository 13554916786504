.report {
  height: 69vh;
  margin: 1% auto;
  width: 70%;
}

.title {
  color: #000000;
  font: Bold 22px/27px Segoe UI;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
  width: 70%;
  margin: auto;
  margin-top: 80px;
}

iframe {
  border: none;
}
