@import '../../assets/styles/variables.scss';

.box {
  margin-top: 10px;
  border-radius: 3px;
  overflow-wrap: break-word;
  max-width: 100%;
}

.warning {
  border: 1px solid #ff0000;
}
.info {
  border: 1px solid #007e93;
}

.title {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: $announcementTitleColor;
  display: flex;
  align-items: center;
  height: 35px;
  justify-content: space-between;
}
.title div {
  cursor: pointer;
  padding: 15px;
}
.warning .title {
  background-color: #ff0000;
}
.info .title {
  background-color: #007e93;
}

.titleContent {
  margin-left: 15px;
}

.content {
  font-family: Lato;
  font-size: 18px;
  color: $announcementContentColor;
  padding: 0px 15px 0 15px;
  margin-left: 1px;
  margin-right: 1px;
  padding-bottom: 10px;
}

.announcementTitle {
  font-size: 24px;
  font-weight: 700;
}

.announcementContent {
  font-size: 18px;
  font-weight: 400;
}

.contentContainer {
  padding-top: 20px;
}
