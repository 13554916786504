$mainColor: #1b4a83;
$altColor: #007e93;
$contentBackgroundColor: #ffffff;
$fontColor: #000000;
$titleColor: #ffffff;
$titleFontFamily: Lato, sans-serif;
$titleFontSize: 20px;
$titleFontWeight: 700;
$titleLineHeight: 22px;
$titleFontCase: uppercase;
$headTitleFontSize: 2em;
$disabledColor: #767676;
$outlineColor: #2684ff;
$inputBGColor: #ffff;
$inputBorder: 1px #cccccc solid;
$inputDisabledBGColor: #f2f2f2;
$inputDisabledBorder: 1px #e6e6e6 solid;
$inputRadius: none;
$disabledBackgroundColor: #e8e8e8;
$disabledColor: #666;
$requiredColor: red;
$hoverColor: #527094;
$altHoverColor: #4d828b;
$announcementTitleBackgroudColor: #ff0000;
$announcementTitleColor: #ffffff;
$announcementContentColor: #000000;
