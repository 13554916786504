.container {
  text-align: center;
}
.container input,
.container p {
  font-size: 18px;
  height: 25px;
  border-radius: 3px;
  margin: 5px;
}
.container input {
  width: 75%;
}
.control {
  display: flex;
  justify-content: space-around;
  margin: 0px auto;
  max-width: 75%;
  margin-bottom: 10px;
}

.control button {
  color: #fff;
  font-size: 18px;
  border: none;
  line-height: 22px;
  margin: 5px 10px;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}
.control button:hover {
  background-color: #08abc7;
  color: rgb(71, 74, 74);
}

.control button:active {
  transform: scale(0.8);
}

.ok {
  background-color: #1b4a83;
}
.cancel {
  background-color: #007e93;
}
