.hide {
  display: none;
}

.container {
  position: relative;
  font-size: 18px;
  margin: 0 10px;
}

.listContainer {
  position: absolute;
  right: 0;
  text-align: right;
  background-color: white;
  border: 1px solid white;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 15px -6px #000000;
  box-shadow: 5px 5px 15px -6px #000000;
  z-index: 2;
}
.listContainer div,
.menuBtn {
  font: inherit;
  text-align: right;
  background: none;
  color: black;
  border: none;
  width: max-content;
  font-size: 18px;
  padding: 0;
  min-width: 100px;
  font-weight: bold;
  cursor: pointer;
  outline: inherit;
}
.listContainer a {
  text-decoration: none;
  color: #000000;
  padding: 10px 10px;
}

.menuBtn {
  color: #fff;
  text-align: right;
}

.listContainer div {
  list-style: none;
  padding: 10px 0;
  position: relative;
  min-width: 150px;
  width: 100%;
  border-bottom: 1px solid #e6e5e5;
}
.listContainer div:hover {
  background-color: #e6e5e5;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0.1;
  z-index: 1;
  height: 300vh;
  width: 300vw;
}
.active {
  opacity: 0.56;
}
