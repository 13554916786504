.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.tooltip .tooltiptext {
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-style: normal;
  color: #000;
  font-weight: 400;
  display: none;
  text-align: center;
  padding: 5px 5px;
  max-width: 40vw;
  position: absolute;
  bottom: 15px;
  background-color: #fff;
  border: solid 1px #585050;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  display: block;
  width: max-content;
}

.leftBox {
  left: 15px;
}

.rightBox {
  right: 15px;
}

.info {
  font-size: 17px;
  font-weight: bold;
  color: #bcbcbc;
}
