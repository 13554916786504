.actionsContainer {
  text-align: center;
}

.actionsContainer button {
  background: none;
  border: none;
  font-family: Arial, sans-serif;
  padding: 10px;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
