@import './grid.scss';
@import './variables.scss';
@import 'react-toastify/dist/ReactToastify.css';

body {
  font-family: Lato;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
}

.App {
  font-family: Lato, sans-serif;
  line-height: 1.5;
}

.content {
  background-color: $contentBackgroundColor;
}

.validation-error-text-wrapper {
  line-height: 14px;
}

.validation-error-text {
  color: red;
  font-size: 16px;
  margin-bottom: 5px;
}

.warning-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.white-link-inside-info a {
  color: #ffff;
}

.space-between {
  justify-content: space-between;
}

.col-10 {
  width: 9.8%;
}
.col-9 {
  width: 90%;
}

.label_text {
  font-size: 14px;
  line-height: 27px;
}

.inline-flex {
  display: inline-flex;
}

.col-direction {
  flex-direction: column;
}

.align-end {
  display: flex;
  align-items: flex-end;
}
.align-center {
  display: flex;
  align-items: center;
}

.link-button-alike {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

input[type='radio'] {
  appearance: none;
  background-color: #fff;
  margin: 0 5px 0 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(0.2em);
}

input[type='radio']:checked {
  border: 0.45em solid #007e93;
}

div.ag-theme-alpine .ag-header-row,
div.ag-theme-alpine .ag-header-row div {
  background-color: #c4e7fe;
  font-size: 16px;
}

div.ag-theme-alpine div.ag-row {
  font-size: 16px;
}
div.ag-theme-alpine div.ag-row.ag-row-odd {
  background-color: #e2efff !important;
}
div.ag-theme-alpine div.ag-row:hover {
  background-color: #c4d6ee !important;
}

.grid-4-1st {
  @extend .container__col-12;
  @extend .container__col-lg-6;
  @extend .container__col-xl-3;
}
.grid-4-2nd,
.grid-4-4th {
  @extend .grid-4-1st;
  @extend .container__col-lg-offset-1;
}
.grid-4-3rd {
  @extend .grid-4-1st;
  @extend .container__col-xl-offset-1;
}
.grid-2-1st {
  @extend .container__col-12;
  @extend .container__col-lg-6;
}
.grid-2-2nd {
  @extend .grid-2-1st;
  @extend .container__col-lg-offset-1;
}
.grid-3-1st {
  @extend .container__col-12;
  @extend .container__col-lg-6;
  @extend .container__col-xl-9;
}
.grid-3-2nd {
  @extend .grid-3-1st;
  @extend .container__col-lg-offset-1;
}

.info-box-with-html {
  text-align: left;
  margin: 15px;
}

.info-box-with-html ul {
  margin-left: -25px;
}
