.icon {
  max-height: 25px;
  margin-right: 3px;
}
.label {
  font-size: 14px;
}

.optioncontainer {
  display: flex;
}
