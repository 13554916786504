.icon {
  color: #1b4a83;
  padding-right: 5px;
}

.container {
  width: 100%;
  margin: 20px 0;
}

.disabled {
  color: gray;
  pointer-events: none;
  cursor: not-allowed;
}

.addNew {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 20px;
}
.error {
  width: 100%;
  color: red;
  font-size: 16px;
  margin-bottom: 5px;
}
