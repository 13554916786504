.App {
  margin: 0 70px;
}

@media only screen and (max-width: 992px) {
  .App,
  input {
    margin: 0;
    text-align: center;
  }
}
