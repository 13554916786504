.icon {
  max-height: 25px;
  margin-right: 3px;
  position: absolute;
}
.imgContainer {
  position: relative;
  min-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label {
  font-size: 14px;
}

.optioncontainer {
  display: flex;
  align-items: center;
}
