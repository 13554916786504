.disabled {
  color: rgb(85, 83, 83);
}

.input[type='radio']:checked:disabled {
  border: 0.45em solid rgb(85, 83, 83);
}

.errors {
  color: red;
  font-size: 16px;
  margin-bottom: 5px;
  width: 100%;
}
.container {
  margin: 30px 0;
  width: 100%;
  display: flex;
}
