.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    padding: 5px 20px;
    font-weight: normal;
    font-size: 16px;
  }

  button:not(:last-of-type) {
    margin-right: 20px;
  }
}

.secondaryActions {
  @extend .container;

  padding-top: 3px;

  input[type='text'] {
    margin: 0 20px 0 0;
    width: 165px;
    padding: 5px;
  }
}

.mainActions {
  @extend .container;

  button {
    width: auto;
    margin-right: 20px;
  }

  input {
    transform: scale(1.4);
    margin: 0 10px 0 0;
  }
}

.error {
  border: 1px solid red;
}

.error:focus {
  outline: none !important;
  border-radius: 3px;
  border: 2px solid red;
}
